import { getRequest, postRequest, removeRequest } from '../axiosRequest.js';
import endPoint from './endPointAddresses.js';

export const create = async (createData) => {
  return await postRequest(endPoint.subscription.LetUsCallYou, createData);
};
export const getAll = async (filterData) => {
  return await postRequest(endPoint.subscription.GetAll, filterData);
};
export const remove = async (id) => {
  return await removeRequest(`${endPoint.subscription.Remove}/${id}`);
};
