import { getRequest, postRequest, putRequest, removeRequest } from '../axiosRequest.js';
import endPoint from './endPointAddresses.js';

export const createSpeakerAvailability = async (createData) => {
  return await postRequest(endPoint.speakerAvailability.Create, createData);
};

export const updateSpeakerAvailability = async (updateData, id) => {
  return await putRequest(`${endPoint.speakerAvailability.Update}/${id}`, updateData);
};

export const removeSpeakerAvailability = async (id) => {
  return await removeRequest(`${endPoint.speakerAvailability.Remove}/${id}`);
};

export const getAllSpeakerAvailabilities = async (id = '') => {
  return await getRequest(`${endPoint.speakerAvailability.GetAll}${id !== '' ? `/${id}` : ''}`);
};

export const getSelectedSpeakerDates = async (filter) => {
  return await postRequest(endPoint.speakerAvailability.GetSelectedSpeakersAndDates, filter);
};
