import { getRequest, postRequest, removeRequest } from '../axiosRequest.js';
import endPoint from './endPointAddresses.js';

export const completedOrdersAttendeeDetails = async (filterData) => {
  return await postRequest(endPoint.reporting.CompletedOrdersAttendeeDetails, filterData);
};
export const getUsersWithPackageAndCount = async (filterData) => {
  return await postRequest(endPoint.reporting.GetUsersWithPackageAndCount, filterData);
};
export const getUsersWithPackageAboutToEnd = async (filterData) => {
  return await postRequest(endPoint.reporting.GetUsersWithPackageAboutToEnd, filterData);
};
export const getUsersWithPackageEnded = async (filterData) => {
  return await postRequest(endPoint.reporting.GetUsersWithPackageEnded, filterData);
};
export const getTrialUsers = async (filterData) => {
  return await postRequest(endPoint.reporting.GetTrialUsers, filterData);
};
export const getUsersHasPackageWithMonthOption = async (filterData) => {
  return await postRequest(endPoint.reporting.GetUsersHasPackageWithMonthOption, filterData);
};
