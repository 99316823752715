import { getRequest, postRequest, putRequest, removeRequest } from '../axiosRequest.js';
import endPoint from './endPointAddresses.js';

export const create = async (createData) => {
  return await postRequest(endPoint.zone.CREATE_ZONE, createData);
};
export const update = async (updateData, id) => {
  return await putRequest(`${endPoint.zone.UPDATE_ZONE}/${id}`, updateData);
};
export const remove = async (id) => {
  return await removeRequest(`${endPoint.zone.REMOVE_ZONE}/${id}`);
};
export const getById = async (id) => {
  return await getRequest(`${endPoint.zone.GETBYID_ZONE}/${id}`);
};
export const getAll = async () => {
  return await getRequest(endPoint.zone.GETALL_ZONE);
};
