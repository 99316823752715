import { Navigate } from 'react-router';

import Cookies from 'js-cookie';

import refreshPage from '../utils/refreshPage.js';

import { storageKeys } from '../constants/Enum.jsx';
import { refreshToken } from './apiServices/authServices.js';
import axios from './axios.js';

const getToken = () => localStorage.getItem(storageKeys.token);
let axiosInterceptor = null;

const AxiosRequest = () => {
  axios.interceptors.request.use(
    (config) => {
      const token = getToken();
      const language = localStorage.getItem(storageKeys.languge);
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      config.headers['Accept-Language'] = language;
      const sessionKey = Cookies.get('SessionKey');

      if (sessionKey) {
        config.headers['X-Session-Key'] = sessionKey;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
};

const handle403Error = async (config) => {
  const newAccessToken = refreshToken();
  if ((await newAccessToken).isSuccess) {
    axios.interceptors.request.use((config) => {
      config.headers['Authorization'] = `Bearer ${newAccessToken.then((val) => val.token)}`;
      return config;
    });
    const method = config.method;
    await axios[method](config.url, config.data);
    refreshPage();
  } else {
    <Navigate to='/account/login' replace />;
  }
};

const AxiosResponse = () => {
  return axios.interceptors.response.use(
    (response) => {
      const sessionKey = response.headers['x-session-key'];
      if (sessionKey) {
        Cookies.set('SessionKey', sessionKey, { secure: true, sameSite: 'Strict' });
      }
      return response;
    },
    async (error) => {
      const { response, config } = error;
      if (response?.status === 403 && getToken() !== null) {
        await handle403Error(config);
      } else if (response?.status === 401) {
        <Navigate to='/unauthorized' replace />;
      }
      return Promise.reject(error);
    }
  );
};

const setInterceptors = () => {
  if (!!axiosInterceptor || axiosInterceptor === 0) {
    axios.interceptors.response.eject(axiosInterceptor);
  }
  AxiosResponse();
  AxiosRequest();
};

export const postRequest = async (URL, Data) => {
  setInterceptors();
  return axios.post(URL, Data);
};

export const putRequest = async (URL, Data) => {
  setInterceptors();
  return axios.put(URL, Data);
};

export const removeRequest = async (URL) => {
  setInterceptors();
  return axios.delete(URL);
};

export const getRequest = async (URL) => {
  setInterceptors();
  return axios.get(URL);
};

export const getFilteredRequest = async (URL, Data) => {
  setInterceptors();
  return axios.get(URL, Data);
};
