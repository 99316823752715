import { getRequest, postRequest, putRequest, removeRequest } from '../axiosRequest.js';
import endPoint from './endPointAddresses.js';

export const create = async (createData) => {
  return await postRequest(endPoint.meetingSchema.Create, createData);
};
export const update = async (updateData) => {
  return await putRequest(endPoint.meetingSchema.Update, updateData);
};
export const remove = async (id) => {
  return await removeRequest(`${endPoint.meetingSchema.Remove}/${id}`);
};
export const getById = async (id) => {
  return await getRequest(`${endPoint.meetingSchema.GetById}/${id}`);
};
export const getAll = async (data) => {
  return await postRequest(endPoint.meetingSchema.GetAll, data);
};
export const getAllAttendeeSchedule = async (data) => {
  return await postRequest(endPoint.meetingSchema.GET_MEETING_ATTENDEE_SCHEDULE, data);
};
export const getAllSessionsCount = async () => {
  return await getRequest(`${endPoint.meetingSchema.GET_ALL_SESSION_COUNT}`);
};
export const getSpeakerDropdownList = async (filter) => {
  return await postRequest(`${endPoint.meetingSchema.GET_SPEAKER_DROPDOWN_LIST}`, filter);
};
export const getTodaysMeetingBySpeaker = async (date) => {
  return await getRequest(`${endPoint.meetingSchema.GET_MEETING_BY_SPEAKER_MEETINGSCHEMA}?date=${date}`);
};
export const getAllSpeakerSchedule = async (id = '') => {
  return await getRequest(`${endPoint.meetingSchema.GetMeetingSpeakerSchedule}${id ? `/${id}` : ''}`);
};
export const getMeetingAttendees = async (data) => {
  return await postRequest(endPoint.meetingSchema.GET_MEETING_ATTENDEES, data);
};
export const attendanceCheck = async (data) => {
  return await postRequest(endPoint.meetingSchema.ATTENDANCE_CHECK, data);
};
export const getSessionHistory = async (id = '') => {
  return await getRequest(`${endPoint.meetingSchema.SessionHistory}${id ? `/${id}` : ''}`);
};
