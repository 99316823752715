import { getRequest, postRequest, putRequest, removeRequest } from '../axiosRequest.js';
import endPoint from './endPointAddresses.js';

export const create = async (createData) => {
  return await postRequest(endPoint.speaker.CREATE_SPEAKER, createData);
};
export const update = async (updateData, id = '') => {
  return await putRequest(`${endPoint.speaker.UPDATE_SPEAKER}${id !== '' ? `/${id}` : ''}`, updateData);
};
export const remove = async (id) => {
  return await removeRequest(`${endPoint.speaker.REMOVE_SPEAKER}/${id}`);
};
export const getById = async (id = '') => {
  return await getRequest(`${endPoint.speaker.GETBYID_SPEAKER}${id !== '' ? `/${id}` : ''}`);
};
export const getAll = async (requestType = '') => {
  const typeData = typeof requestType === 'object' ? true : false;
  if (typeData || requestType === '') {
    return await postRequest(`${endPoint.speaker.GETALL_SPEAKER}`, typeData ? requestType : null);
  } else if (requestType !== '' && !typeData) {
    return await getRequest(`${endPoint.speaker.GETALL_SPEAKER}${`/${requestType}`}`);
  }
};
export const getFiltred = async (pageNumber, pageSize, data) => {
  return await postRequest(`${endPoint.speaker.GETFILTRED_SPEAKER}/${pageNumber}/${pageSize}`, data);
};
export const speakerPaymentBySpeaker = async (id = '') => {
  return await getRequest(`${endPoint.speaker.SPEAKER_PAYMENT}${id !== '' ? `/${id}` : ''}`);
};
export const getSelectedMentor = async (data) => {
  return await postRequest(endPoint.speaker.GetSelectedMentor, data);
};
