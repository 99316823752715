import { getRequest, postRequest, putRequest, removeRequest } from '../axiosRequest.js';
import endPoint from './endPointAddresses.js';

export const create = async (data) => {
  return await postRequest(endPoint.meeting.Create, data);
};
export const update = async (data) => {
  return await putRequest(endPoint.meeting.Update, data);
};
export const getMeetingsBySchemaId = async (filter) => {
  return await postRequest(endPoint.meeting.GetMeetingsBySchemaId, filter);
};
export const changePaymentStatus = async (id) => {
  return await postRequest(`${endPoint.meeting.ChangePaymentStatus}/${id}`);
};
export const cancelPenalty = async (id) => {
  return await postRequest(`${endPoint.meeting.CancelPenalty}/${id}`);
};
