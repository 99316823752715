import { getRequest, postRequest, putRequest, removeRequest } from '../axiosRequest.js';
import endPoint from './endPointAddresses.js';

export const create = async (createData) => {
  return await postRequest(endPoint.campaign.Create, createData);
};
export const update = async (updateData) => {
  return await putRequest(endPoint.campaign.Update, updateData);
};
export const remove = async (id) => {
  return await removeRequest(`${endPoint.campaign.Remove}/${id}`);
};
export const getAll = async () => {
  return await getRequest(endPoint.campaign.GetAll);
};
export const getById = async (id) => {
  return await getRequest(`${endPoint.campaign.GetById}/${id}`);
};
export const getActiveCampaigns = async () => {
  return await getRequest(endPoint.campaign.GetActiveCampaigns);
};
export const getSelectedCampaignDetails = async (id) => {
  return await getRequest(`${endPoint.campaign.GetSelectedCampaignDetails}/${id}`);
};
export const getDashbordBanner = async () => {
  return await getRequest(endPoint.campaign.GetDashbordBanner);
};
