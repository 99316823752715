import { getRequest, postRequest, putRequest, removeRequest } from '../axiosRequest.js';
import endPoint from './endPointAddresses.js';

export const create = async (createData) => {
  return await postRequest(endPoint.order.CREATE_ORDER, createData);
};
export const selectSession = async (createData) => {
  return await postRequest(endPoint.order.SELECT_SESSION, createData);
};
export const update = async (updateData) => {
  return await putRequest(`${endPoint.order.UPDATE_ORDER}`, updateData);
};

export const updateOrderItem = async (updateData, id) => {
  return await putRequest(`${endPoint.order.UPDATE_ORDER_ITEM}/${id}`, updateData);
};
export const remove = async (id) => {
  return await removeRequest(`${endPoint.order.REMOVE_ORDER_ITEM}/${id}`);
};
export const removeOrder = async (id) => {
  return await removeRequest(`${endPoint.order.REMOVE_ORDER}/${id}`);
};
export const getById = async (id) => {
  return await getRequest(`${endPoint.order.GETBYID_ORDER}/${id}`);
};
export const getAll = async (filter) => {
  return await postRequest(`${endPoint.order.GETALL_ORDER}`, filter);
};
export const getEftControl = async (filter) => {
  return await postRequest(`${endPoint.order.EFT_CONTROL}`, filter);
};
export const updateEft = async (id) => {
  return await putRequest(`${endPoint.order.EFT_UPDATE}/${id}`);
};
export const getMySubscriptionPeriod = async () => {
  return await getRequest(`${endPoint.order.GET_MYSUBSCRIPTION_PERIOD}`);
};
export const getOrders = async (id = '') => {
  return await getRequest(`${endPoint.order.GET_ORDERS}${id !== '' ? '/' + id : ''}`);
};
export const getBasketItems = async (id = '') => {
  return await getRequest(`${endPoint.order.GET_BASKET_ITEMS}${id !== '' ? '/' + id : ''}`);
};
export const selectLaterOrder = async (updateData, id = '') => {
  return await putRequest(`${endPoint.order.SET_DATE_ORDER_ITEM}${id !== '' ? '/' + id : ''}`, updateData);
};
export const getEarnedFreeSessions = async () => {
  return await getRequest(`${endPoint.order.GET_EARNED_FREESESSIONS}`);
};
export const getGiftSessions = async () => {
  return await getRequest(`${endPoint.order.GET_GIFT_SESSIONS}`);
};
export const senfGiftSessionToFriend = async (createData) => {
  return await postRequest(`${endPoint.order.SEND_GIFT_SESSION_TO_FRIEND}`, createData);
};
export const redeemGiftCode = async (createData) => {
  return await postRequest(`${endPoint.order.REEDEM_GIFT_CODE}`, createData);
};
export const cancelGift = async (id) => {
  return await removeRequest(`${endPoint.order.CANCEL_GIFT}/${id}`);
};
export const orderSetDate = async (createData) => {
  return await postRequest(`${endPoint.order.ORDER_SET_DATE}`, createData);
};
export const unSetDate = async (id) => {
  return await postRequest(`${endPoint.order.UNSET_DATE}/${id}`);
};
export const getMeetingsByOrder = async (filter) => {
  return await postRequest(endPoint.order.GetMeetingsByOrder, filter);
};
export const payments = async (filter) => {
  return await postRequest(endPoint.order.Payments, filter);
};
export const getOrderDetails = async (id) => {
  return await getRequest(`${endPoint.order.GetOrderDetails}/${id}`);
};
export const getOrderItemDetails = async (id) => {
  return await getRequest(`${endPoint.order.GetOrderItemDetails}/${id}`);
};
export const orderItemUpdate = async (data) => {
  return await postRequest(endPoint.order.OrderItemUpdate, data);
};
export const todaysTrialList = async (data) => {
  return await postRequest(endPoint.order.TodaysTrialList, data);
};
export const upcomingTrialList = async (data) => {
  return await postRequest(endPoint.order.UpcomingTrialList, data);
};
export const renewOrderItemCalculation = async (data) => {
  return await postRequest(endPoint.order.RenewOrderItemCalculation, data);
};
export const renewOrderItem = async (data) => {
  return await postRequest(endPoint.order.RenewOrderItem, data);
};
export const calculationRemainingSessionFee = async (filter) => {
  return await postRequest(endPoint.order.CalculationRemainingSessionFee, filter);
};
export const getOrdersByAttendeeId = async (id) => {
  return await getRequest(`${endPoint.order.GetOrdersByAttendeeId}/${id}`);
};
export const demoCreation = async (data) => {
  return await postRequest(endPoint.order.DemoCreation, data);
};
