import { getRequest, postRequest, putRequest, removeRequest } from '../axiosRequest.js';
import endPoint from './endPointAddresses.js';

export const create = async (createData) => {
  return await postRequest(endPoint.becomeAPartner.CREATE_BECOMEAPARTNER, createData);
};
export const update = async (updateData, id) => {
  return await putRequest(`${endPoint.becomeAPartner.UPDATE_BECOMEAPARTNER}/${id}`, updateData);
};
export const remove = async (id) => {
  return await removeRequest(`${endPoint.becomeAPartner.REMOVE_BECOMEAPARTNER}/${id}`);
};
export const getById = async (id) => {
  return await getRequest(`${endPoint.becomeAPartner.GETBYID_BECOMEAPARTNER}/${id}`);
};
export const getAll = async () => {
  return await getRequest(endPoint.becomeAPartner.GETALL_BECOMEAPARTNER);
};
