import { postRequest } from '../axiosRequest.js';
import endPoint from './endPointAddresses.js';

export const paymentInstallment = async (cardDetails) => {
  return await postRequest(endPoint.payment.PAYMENT_INSTALLMENT, cardDetails);
};

export const payment = async (cardDetails) => {
  return await postRequest(endPoint.payment.PAYMENT_PAYMENT, cardDetails);
};

export const paymentCallback = async (data) => {
  return await postRequest(endPoint.payment.PAYMENT_CALLBACK, data);
};
