import { getRequest, postRequest } from '../axiosRequest.js';
import endPoint from './endPointAddresses.js';

export const getAll = async (requestType = '') => {
  const typeData = typeof requestType === 'object' ? true : false;
  if (typeData || requestType === '') {
    return await postRequest(`${endPoint.speakerEffort.GetAll}`, typeData ? requestType : null);
  } else if (requestType !== '' && !typeData) {
    return await getRequest(`${endPoint.speakerEffort.GetAll}${`/${requestType}`}`);
  }
};
export const getSpeakerDetail = async (data) => {
  return await postRequest(endPoint.speakerEffort.GetBySpeaker, data);
};
