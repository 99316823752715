import { getRequest, postRequest } from '../axiosRequest.js';
import endPoint from './endPointAddresses.js';

export const create = async (createData) => {
  return await postRequest(endPoint.classroom.CommitMessage, createData);
};
export const getPrivateRoomMembers = async () => {
  return await getRequest(endPoint.classroom.GetPrivateRoomMembers);
};
export const getPrivateMessages = async () => {
  return await getRequest(endPoint.classroom.GetPrivateMessages);
};
export const getGroupMessages = async (id) => {
  return await getRequest(`${endPoint.classroom.GetGroupMessages}/${id}`);
};
export const getGroupRoomMembers = async () => {
  return await getRequest(endPoint.classroom.GetGroupRoomMembers);
};
export const hideClassroomGroup = async (data) => {
  return await postRequest(endPoint.classroom.HideClassroomGroup, data);
};
