import { getRequest, postRequest, putRequest, removeRequest } from '../axiosRequest.js';
import endPoint from './endPointAddresses.js';

export const create = async (createData) => {
  return await postRequest(endPoint.location.CREATE_LOCATION, createData);
};
export const update = async (updateData, id) => {
  return await putRequest(`${endPoint.location.UPDATE_LOCATION}/${id}`, updateData);
};
export const remove = async (id) => {
  return await removeRequest(`${endPoint.location.REMOVE_LOCATION}/${id}`);
};
export const getById = async (id) => {
  return await getRequest(`${endPoint.location.GETBYID_LOCATION}/${id}`);
};
export const getAll = async () => {
  return await getRequest(endPoint.location.GETALL_LOCATION);
};
export const getLocationsByUserArea = async (area) => {
  return await getRequest(`${endPoint.location.GetLocationsByUserArea}/${area}`);
};
