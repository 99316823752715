import { storageKeys } from '../../constants/Enum.jsx';
import { getRequest, postRequest, putRequest, removeRequest } from '../axiosRequest.js';
import endPoint from './endPointAddresses.js';

export const create = async (createData) => {
  return await postRequest(endPoint.staticData.CREATE_STATIC, createData);
};
export const update = async (updateData, id) => {
  return await putRequest(`${endPoint.staticData.UPDATE_STATIC}/${id}`, updateData);
};
export const remove = async (id) => {
  return await removeRequest(`${endPoint.staticData.REMOVE_STATIC}/${id}`);
};
export const getById = async (id) => {
  return await getRequest(`${endPoint.staticData.GETBYID_STATIC}/${id}`);
};
export const getAll = async () => {
  return await getRequest(endPoint.staticData.GETALL_STATIC);
};
export const getAllByDataType = async (dataType) => {
  const lang = localStorage.getItem(storageKeys.languge);
  const myData = {
    dataType: dataType,
    userLanguage: lang.split('-')[0].toLowerCase(),
  };
  return await postRequest(`${endPoint.staticData.GETALLBYDATATYPE_STATIC}`, myData);
};
