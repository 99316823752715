import { getRequest, postRequest, putRequest, removeRequest } from '../axiosRequest.js';
import endPoint from './endPointAddresses.js';

export const create = async (createData) => {
  return await postRequest(endPoint.banner.Create, createData);
};
export const update = async (updateData) => {
  return await putRequest(endPoint.banner.Update, updateData);
};
export const remove = async (id) => {
  return await removeRequest(`${endPoint.banner.Remove}/${id}`);
};
export const getAll = async () => {
  return await getRequest(endPoint.banner.GetAll);
};
export const getById = async (id) => {
  return await getRequest(`${endPoint.banner.GetById}/${id}`);
};
export const getByZone = async () => {
  return await getRequest(endPoint.banner.GetActiveBannerByZone);
};
