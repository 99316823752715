import { getRequest, postRequest, putRequest, removeRequest } from '../axiosRequest.js';
import endPoint from './endPointAddresses.js';

export const create = async (createData) => {
  return await postRequest(endPoint.companyForm.Create, createData);
};
export const update = async (id) => {
  return await putRequest(`${endPoint.companyForm.Update}/${id}`);
};
export const remove = async (id) => {
  return await removeRequest(`${endPoint.companyForm.Remove}/${id}`);
};
export const getById = async (id) => {
  return await getRequest(`${endPoint.companyForm.GetById}/${id}`);
};
export const getAll = async () => {
  return await getRequest(endPoint.companyForm.GetAll);
};
