import { getRequest, postRequest } from '../axiosRequest.js';
import endPoint from './endPointAddresses.js';

export const createWheelParticipation = async (createData) => {
  return await postRequest(endPoint.wheelEvent.CreateWheelParticipation, createData);
};
export const reedemReward = async (key) => {
  return await postRequest(`${endPoint.wheelEvent.ReedemReward}/${key}`);
};
export const getActiveWheel = async () => {
  return await getRequest(endPoint.wheelEvent.GetActiveWheel);
};
