import { getRequest, postRequest, putRequest, removeRequest } from '../axiosRequest.js';
import endPoint from './endPointAddresses.js';

export const create = async (createData) => {
  return await postRequest(endPoint.couponCodes.CREATE_COUPONCODES, createData);
};
export const update = async (updateData) => {
  return await putRequest(`${endPoint.couponCodes.UPDATE_COUPONCODES}`, updateData);
};
export const remove = async (id) => {
  return await removeRequest(`${endPoint.couponCodes.REMOVE_COUPONCODES}/${id}`);
};
export const getById = async (id) => {
  return await getRequest(`${endPoint.couponCodes.GETBYID_COUPONCODES}/${id}`);
};
export const getByName = async (name) => {
  return await getRequest(`${endPoint.couponCodes.GETBYNAME_COUPONCODES}`, name);
};
export const getAll = async () => {
  return await getRequest(endPoint.couponCodes.GETALL_COUPONCODES);
};
