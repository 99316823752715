import { getRequest, postRequest } from '../axiosRequest.js';
import endPoint from './endPointAddresses.js';

export const getBySpeakerId = async (id) => {
  return await getRequest(`${endPoint.calendar.GetCalendarData}/${id}`);
};
export const getAll = async (date) => {
  return await postRequest(endPoint.calendar.GetAllCalendarData, date);
};
export const getCalendarData = async (data) => {
  return await postRequest(`${endPoint.calendar.GetAllCalendarDataWithFilter}`, data);
};
export const getSelectedSpeakerData = async (data) => {
  return await postRequest(`${endPoint.calendar.GetSelectedSpeakerCalenderData}`, data);
};
export const getCellDetails = async (data) => {
  return await postRequest(endPoint.calendar.GetCellDetails, data);
};
export const getAllCalendarDataWithFilterBO = async (data) => {
  return await postRequest(endPoint.calendar.GetAllCalendarDataWithFilterBO, data);
};
export const getCellDetailsBO = async (data) => {
  return await postRequest(endPoint.calendar.GetCellDetailsBO, data);
};
export const getMeetingAttendees = async (data) => {
  return await postRequest(endPoint.calendar.GetMeetingAttendees, data);
};
