import { getRequest, postRequest, removeRequest } from '../axiosRequest.js';
import endPoint from './endPointAddresses.js';

export const create = async (createData) => {
  return await postRequest(endPoint.feedback.Create, createData);
};
export const remove = async (id) => {
  return await removeRequest(`${endPoint.feedback.Remove}/${id}`);
};
export const getAll = async () => {
  return await getRequest(endPoint.feedback.GetAll);
};
