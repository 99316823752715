import { getRequest, postRequest, putRequest, removeRequest } from '../axiosRequest.js';
import endPoint from './endPointAddresses.js';

export const create = async (createData) => {
  return await postRequest(endPoint.ageInterval.CREATE_AGEINTERVAL, createData);
};
export const update = async (updateData, id) => {
  return await putRequest(`${endPoint.ageInterval.UPDATE_AGEINTERVAL}/${id}`, updateData);
};
export const remove = async (id) => {
  return await removeRequest(`${endPoint.ageInterval.REMOVE_AGEINTERVAL}/${id}`);
};
export const getById = async (id) => {
  return await getRequest(`${endPoint.ageInterval.GETBYID_AGEINTERVAL}/${id}`);
};
export const getAll = async (id = '') => {
  return await getRequest(`${endPoint.ageInterval.GETALL_AGEINTERVAL}${id !== '' ? `/${id}` : ''}`);
};
