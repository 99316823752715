import { getRequest, postRequest, putRequest, removeRequest } from '../axiosRequest.js';
import endPoint from './endPointAddresses.js';

export const create = async (createData) => {
  return await postRequest(endPoint.sessionCategory.CREATE_SESSIONCATEGORY, createData);
};
export const update = async (updateData, id) => {
  return await putRequest(`${endPoint.sessionCategory.UPDATE_SESSIONCATEGORY}/${id}`, updateData);
};
export const remove = async (id) => {
  return await removeRequest(`${endPoint.sessionCategory.REMOVE_SESSIONCATEGORY}/${id}`);
};
export const getById = async (id) => {
  return await getRequest(`${endPoint.sessionCategory.GETBYID_SESSIONCATEGORY}/${id}`);
};
export const getAll = async () => {
  return await getRequest(endPoint.sessionCategory.GETALL_SESSIONCATEGORY);
};
